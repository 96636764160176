<!-- Mto particular de Centros Territoriales -->

<template>
  <div class="ct_M">
    <!-- Base Mto-->
    <base_M
      v-if="schema"
      :schema="schema"
      :titulo="titulo"
      :accion="accion"
      @onEvent="event_capture"
    >
      <!-- Slot con los controles del Mto -->
      <template v-slot:ctrls="{}">
        <v-text-field
          v-bind="$input"
          v-model="schema.ctrls.name.value"
          :label="schema.ctrls.name.label"
          :disabled="!edicion"
        >
        </v-text-field>

        <v-text-field
          v-bind="$input"
          v-model="schema.ctrls.empresa.value"
          :label="schema.ctrls.empresa.label"
          :disabled="!edicion"
        >
        </v-text-field>

        <v-text-field
          v-bind="$input"
          v-model="schema.ctrls.dire.value"
          :label="schema.ctrls.dire.label"
          :disabled="!edicion"
        >
        </v-text-field>

        <v-row no-gutters>
          <v-col md="3">
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.cpo.value"
              :label="schema.ctrls.cpo.label"
              :disabled="!edicion"
            >
            </v-text-field>
          </v-col>

          <v-col md="9">
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.pob.value"
              :label="schema.ctrls.pob.label"
              :disabled="!edicion"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col md="6">
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.tfn1.value"
              :label="schema.ctrls.tfn1.label"
              :disabled="!edicion"
            >
            </v-text-field>
          </v-col>

          <v-col md="6">
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.tfn2.value"
              :label="schema.ctrls.tfn2.label"
              :disabled="!edicion"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col md="6">
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.fax1.value"
              :label="schema.ctrls.fax1.label"
              :disabled="!edicion"
            >
            </v-text-field>
          </v-col>

          <v-col md="6">
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.fax2.value"
              :label="schema.ctrls.fax2.label"
              :disabled="!edicion"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          v-bind="$input"
          v-model="schema.ctrls.email1.value"
          :label="schema.ctrls.email1.label"
          :disabled="!edicion"
        >
        </v-text-field>

        <v-text-field
          v-bind="$input"
          v-model="schema.ctrls.email2.value"
          :label="schema.ctrls.email2.label"
          :disabled="!edicion"
        >
        </v-text-field>

        <v-text-field
          v-bind="$input"
          v-model="schema.ctrls.cont1.value"
          :label="schema.ctrls.cont1.label"
          :disabled="!edicion"
        >
        </v-text-field>

        <v-text-field
          v-bind="$input"
          v-model="schema.ctrls.cont1_email.value"
          :label="schema.ctrls.cont1_email.label"
          :disabled="!edicion"
        >
        </v-text-field>

        <v-text-field
          v-bind="$input"
          v-model="schema.ctrls.cont2.value"
          :label="schema.ctrls.cont2.label"
          :disabled="!edicion"
        >
        </v-text-field>

        <v-text-field
          v-bind="$input"
          v-model="schema.ctrls.cont2_email.value"
          :label="schema.ctrls.cont2_email.label"
          :disabled="!edicion"
        >
        </v-text-field>

        <ctrlobs :schema="schema.ctrls.obs" :edicion="edicion"> </ctrlobs>
      </template>
    </base_M>
  </div>
</template>

<script>
import { mixinMto } from "@/mixins/mixinMto.js";
import plugs from "@/common/general_plugs";
const base_M = () => plugs.groute("base_M.vue", "base");
const ctrlobs = () => plugs.groute("ctrlobs.vue", "comp");

export default {
  mixins: [mixinMto],
  components: { base_M, ctrlobs },
  props: {
    id_accion: { type: Object, default: null },
    btra: { type: [Array, Object], default: undefined }
  },

  data() {
    return {
      mto: "centrosT",
      titulo: "Mantenimiento Centros Territoriales",
      schema: null,
      id: this.id_accion.id,
      accion: this.id_accion.accion,
      edicion: false,
      showpass1: false
    };
  },

  methods: {
    // gestor de eventos
    event_capture(evt) {
      console.log("*** event_capture ct_M. Accion: " + evt.accion + " ***");

      if (this.$isFunction(this[evt.accion])) {
        this[evt.accion](evt);
        return;
      }
      this.acciones_Mto(evt);
    }
  }
};
</script>
